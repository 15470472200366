import React from 'react';
import * as styles from './footer.module.scss';

export default function Footer({ title }) {
    return (
        <footer className={styles.footer + ' footer'}>
            <p className="paragraph has-text-centered">
                {title} <a href="mailto:hi@bas.io">Feedback?</a>
            </p>
        </footer>
    );
}
