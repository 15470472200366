import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import ogImage from '../../../assets/images/og_image.png';

const SEO = ({ title, description, image, article }) => {
    const { pathname } = useLocation();
    const { site }     = useStaticQuery(query);

    const { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage, twitterUsername } = 
        site.siteMetadata;

    const seo = {
        title      : title || defaultTitle,
        description: description || defaultDescription,
        image      : `${siteUrl}${image || defaultImage}`,
        url        : `${siteUrl}${pathname}`,
    };

    return (
        <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            {ogImage && <meta name="image" content={ogImage} />}

            {seo.url && <meta property="og:url" content={seo.url} />}

            <meta property="og:type" content="profile" />

            {seo.title && <meta property="og:title" content={seo.title} />}

            {seo.description && <meta property="og:description" content={seo.description} />}

            {ogImage && <meta property="og:image" content={ogImage} />}

            <meta name="twitter:card" content="summary_large_image" />

            {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}

            {seo.title && <meta name="twitter:title" content={seo.title} />}

            {seo.description && <meta name="twitter:description" content={seo.description} />}

            {ogImage && <meta name="twitter:image" content={ogImage} />}
        </Helmet>
    );
};

export default SEO;

SEO.propTypes = {
    title      : PropTypes.string,
    description: PropTypes.string,
    image      : PropTypes.string,
    article    : PropTypes.bool,
};

SEO.defaultProps = {
    title      : null,
    description: null,
    image      : null,
    article    : false,
};

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl           : url
                defaultImage      : image
            }
        }
    }
`;
