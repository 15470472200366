import React from 'react';
import DarkModeSwitch from '../DarkModeSwitch';
import * as styles from './navbar.module.scss';
import { Link as GatsbyLink } from 'gatsby';
import { usePageContext } from '@3nvi/gatsby-theme-intl';

export default function NavBar({ theme, toggleTheme }) {
    const { lang, originalPath } = usePageContext();
    const otherLang              = lang === 'en' ? 'nl' : 'en';

    return (
        <nav className="navbar is-transparent" role="navigation" aria-label="main navigation">
            <div className={'navbar-brand is-active is-transparent ' + styles.navbarBrand}>
                {/* <a className={'navbar-item ' + styles.languageLink} href={href}>
                    {lang}
                </a> */}
                <GatsbyLink
                    className  = {'navbar-item ' + styles.languageLink}
                    aria-label = {`Change language to ${otherLang}`}
                    key        = {otherLang}
                    to         = {`/${otherLang}${originalPath}`}
                >
                    {lang === 'en' ? 'View in Dutch' : 'View in English'}
                </GatsbyLink>
                <DarkModeSwitch theme={theme} toggleTheme={toggleTheme} />
            </div>
        </nav>
    );
}
