import React from 'react';
import Seo from '../SEO';
import NavBar from '../NavBar';
import Footer from '../Footer';
import { useTranslation } from '@3nvi/gatsby-theme-intl';

export default function Layout({ className, theme, toggleTheme, children }) {
    const { t } = useTranslation();
    return (
        <main className={className}>
            <Seo />
            <NavBar theme={theme} toggleTheme={toggleTheme} />
            {children}
            <Footer title={t('footer.title')} />
        </main>
    );
}
