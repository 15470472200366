import React from 'react';
import Switch from 'react-switch';
import * as styles from './darkmodeswitch.module.scss';

export default function DarkModeSwitch({ theme, toggleTheme }) {
    if (theme == null) {
        return null;
    }
    return (
        <div className={styles.switchContainer}>
            <Switch
                onChange      = {(e) => toggleTheme(e ? 'dark' : 'light')}
                checked       = {theme === 'dark'}
                onColor       = "#656580"
                onHandleColor = "#383849"
                offColor      = "#ccc"
                uncheckedIcon = {
                    <span role="img" aria-label="dark mode" className={styles.icon}>
                        🌘
                    </span>
                }
                checkedIcon={
                    <span role="img" aria-label="light mode" className={styles.icon}>
                        🌖
                    </span>
                }
            />
        </div>
    );
}
