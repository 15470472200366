import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import { useTranslation } from '@3nvi/gatsby-theme-intl';
import { usePageContext } from '@3nvi/gatsby-theme-intl';

const IndexPage = () => {
    const { t }    = useTranslation();
    const { lang } = usePageContext();

    return (
        <ThemeToggler>
            {({ theme, toggleTheme }) => (
                <Layout className="page" theme={theme} toggleTheme={toggleTheme}>
                    <section className="container">
                        <div className="columns">
                            <div className="column is-5 is-offset-2" style={{ marginTop: 'auto' }}>
                                <h1 dangerouslySetInnerHTML={{ __html: t('index.title') }} />
                                <p className={'subtitle'} dangerouslySetInnerHTML={{ __html: t('index.subtitle') }} />
                            </div>
                            <div className="column is-3 mt-3" style={{ paddingBottom: '17px' }}>
                                <StaticImage
                                    src         = "../../assets/images/profile_transparent.png"
                                    alt         = "Hi, this is me"
                                    placeholder = "blurred"
                                    layout      = "constrained"
                                    width       = {400}
                                />
                            </div>
                        </div>
                        <div className="columns is-multiline">
                            <div className="column is-8 is-offset-2 mt-2">
                                {t('index.paragraphs', { returnObjects: true }).map((p, i) => {
                                    return (
                                        <p
                                            key                     = {'paragraph-' + i}
                                            className               = {(i === 0 ? '' : 'mt-3') + ' paragraph'}
                                            dangerouslySetInnerHTML = {{ __html: p }}
                                        />
                                    );
                                })}
                            </div>

                            <div className="column is-8 is-offset-2 has-text-centered">
                                {theme === null ? null : theme === 'dark' ? (
                                    lang === 'nl' ? (
                                        <StaticImage
                                            src         = "../../assets/images/way_of_working/way_of_working_nl_dark.png"
                                            alt         = "Successful innovation"
                                            placeholder = "blurred"
                                            layout      = "constrained"
                                            width       = {400}
                                        />
                                    ) : (
                                        <StaticImage
                                            src         = "../../assets/images/way_of_working/way_of_working_en_dark.png"
                                            alt         = "Successful innovation"
                                            placeholder = "blurred"
                                            layout      = "constrained"
                                            width       = {400}
                                        />
                                    )
                                ) : lang === 'nl' ? (
                                    <StaticImage
                                        src         = "../../assets/images/way_of_working/way_of_working_nl.png"
                                        alt         = "Successful innovation"
                                        placeholder = "blurred"
                                        layout      = "constrained"
                                        width       = {400}
                                    />
                                ) : (
                                    <StaticImage
                                        src         = "../../assets/images/way_of_working/way_of_working_en.png"
                                        alt         = "Successful innovation"
                                        placeholder = "blurred"
                                        layout      = "constrained"
                                        width       = {400}
                                    />
                                )}
                            </div>
                        </div>
                    </section>

                    <section className="container mt-6">
                        <div className="columns">
                            <div className="column is-8 is-offset-2">
                                <h2>{t('index.projects.title')}</h2>
                                <p className="paragraph">{t('index.projects.subtitle')}</p>
                            </div>
                        </div>

                        {t('index.projects.items', { returnObjects: true }).map((project, i) => (
                            <div key={'project-' + i} className="columns">
                                <div className="column is-2 is-offset-2 is-hidden-mobile has-border-top">
                                    <div className="tags mt-1">
                                        {project.tags.map((tag, i) => (
                                            <span key={'tag-' + i} className="tag">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="column is-6 has-border-top">
                                    <h3>{project.title}</h3>
                                    <p className="paragraph" dangerouslySetInnerHTML={{ __html: project.paragraph }} />
                                    <div className="tags mt-3 is-hidden-tablet">
                                        {project.tags.map((tag, i) => (
                                            <span key={'tag-mobile-' + i} className="tag">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </section>

                    <section className="container mt-6">
                        <div className="columns is-multiline">
                            <div className="column is-8 is-offset-2">
                                <h2>{t('index.experience.title')}</h2>
                                <div className="content">
                                    <p className="paragraph">{t('index.experience.subtitle')}</p>
                                </div>
                            </div>
                            <div className="column is-8 is-offset-2 has-border-top content">
                                <p className="paragraph">{t('index.roles.title')}</p>
                                <ul>
                                    {t('index.roles.items', { returnObjects: true }).map((role, i) => (
                                        <li key={'role-' + i}>{role}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="column is-8 is-offset-2 has-border-top content">
                                <p className="paragraph">{t('index.skills.title')}</p>
                                <div className="tags mt-3">
                                    {t('index.skills.skills', { returnObjects: true }).map((skill, i) => (
                                        <span key={'skill-' + i} className="tag">
                                            {skill}
                                        </span>
                                    ))}
                                    {t('index.skills.tools', { returnObjects: true }).map((tool, i) => (
                                        <span key={'tool-' + i} className="tag is-tool">
                                            {tool}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="contact" className="container mt-6">
                        <div className="columns is-multiline">
                            <div className="column is-8 is-offset-2">
                                <h2>{t('index.contact.title')}</h2>
                                <p className="paragraph">{t('index.contact.subtitle')}</p>
                            </div>
                            {t('index.contact.items', { returnObjects: true }).map((c, i) => (
                                <div key={'contact-' + i} className="column is-8 is-offset-2 has-border-top">
                                    <p className="paragraph" dangerouslySetInnerHTML={{ __html: c }} />
                                </div>
                            ))}
                        </div>
                    </section>
                </Layout>
            )}
        </ThemeToggler>
    );
};

export default IndexPage;
